<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card color="success" light max-width="100%" width="400" class="px-5 py-3">
          <template v-slot:heading>
            <div class="text-center">
              <h1 v-if="recover" class="display-2 font-weight-bold mb-2">
                {{ $t("Resetare parolă") }}
              </h1>
              <h1 v-else-if="resetToken" class="display-2 font-weight-bold mb-2">
                {{ $t("Schimbă parolă") }}
              </h1>
              <h1 v-else class="display-2 font-weight-bold mb-2">
                {{ $t("Login") }}
              </h1>
            </div>
          </template>
          <v-card-text class="text-center">
            <v-form ref="formChange" @submit.prevent>
              <v-text-field
                v-if="!resetToken"
                v-model="username"
                color="primary"
                autofocus
                :label="$t('Email/Utilizator')"
                :rules="inputValidate"
                prepend-icon="mdi-email"
                @keydown.enter="$refs.password.focus()"
              />
              <v-text-field
                v-if="!recover"
                ref="password"
                v-model="password"
                color="primary"
                :label="$t('Parolă')"
                :rules="inputValidate"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append.prevent="show = !show"
                @keydown.enter="login"
              />
              <v-text-field
                v-if="resetToken"
                ref="passwordConfirm"
                v-model="passwordConfirm"
                color="primary"
                :label="$t('Confirmare parolă')"
                :rules="inputValidateConfirm"
                prepend-icon="mdi-lock-outline"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append.prevent="show = !show"
                @keydown.enter="resetPassword"
              />
              <!-- <v-checkbox v-model="remember" :label="$t('Remember me')" /> -->
            </v-form>
            <v-btn v-if="recover" color="primary" @click="recoverPassword">
              {{ $t("Resetare") }}
            </v-btn>
            <v-btn v-else-if="resetToken" color="primary" @click="recoverPassword">
              {{ $t("Schimbă") }}
            </v-btn>
            <v-btn v-else color="success" @click="login">
              {{ $t("login") }}
            </v-btn>

            <!-- <v-btn-toggle tile dense :value="lang" @change="changeLang">
              <v-btn value="ro">{{ $t("romanian") }}</v-btn>
              <v-btn value="en">{{ $t("english") }}</v-btn>
            </v-btn-toggle> -->
            <br />
            <br />
            <v-btn v-if="!recover && !resetToken" color="primary" text @click="recover = true">
              {{ $t("Am uitat parola") }}
            </v-btn>
            <v-btn
              v-if="recover || resetToken"
              color="success"
              text
              @click="
                recover = false
                resetToken = ''
              "
            >
              {{ $t("login") }}
            </v-btn>
            <!-- <v-btn
              :text="$i18n.locale == 'en'"
              :outlined="$i18n.locale == 'ro'"
              :color="$i18n.locale == 'ro' ? 'primary' : ''"
              value="ro"
              @click="changeLang('ro')"
            >
              {{ $t("romanian") }}
            </v-btn>
            <v-btn
              :text="$i18n.locale == 'ro'"
              :outlined="$i18n.locale == 'en'"
              :color="$i18n.locale == 'en' ? 'primary' : ''"
              value="en"
              @click="changeLang('en')"
            >
              {{ $t("english") }}
            </v-btn> -->
            <!-- <br />
            <v-menu>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ $t("lang") }}</span>
              </template>
              <v-list>
                <v-list-item @click="changeLang('ro')">
                  <v-list-item-title>{{ $t("romanian") }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeLang('en')">
                  <v-list-item-title>{{ $t("english") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>
<script>
import axios from "../../plugins/axios"
import { EventBus } from "../../EventBus"
import md5 from "js-md5"
export default {
  name: "PagesLogin",
  components: {
    // PagesBtn: () => import("./components/Btn")
  },
  data: () => ({ show: false, recover: false, username: "", password: "", passwordConfirm: "", resetToken: "" }),
  computed: {
    inputValidate() {
      const rules = []
      rules.push(v => !!v || this.$t("required"))
      return rules
    },
    inputValidateConfirm() {
      const rules = []
      rules.push(v => !!v || this.$t("required"))
      rules.push(v => v == this.password || this.$t("Parolă neconfirmată"))
      return rules
    },
    lang() {
      //this.$log("langgg ", this.$i18n.locale)
      return this.$i18n.locale
    },
    remember: {
      get() {
        return this.$store.getters.accountRemember
      },
      set(val) {
        this.$log("accountRemember set ", val)
        this.$store.commit("accountRemember", val)
      }
    }
  },
  created() {
    this.$store.commit("accountRemember", true)
    this.$log("this.$route.query ", this.$route.query)
    if (this.$route.query.token) {
      this.resetToken = this.$route.query.token
    }
  },
  methods: {
    recoverPassword() {
      if (this.$refs.formChange.validate()) {
        if (this.resetToken) {
          axios.post("reset_password/" + this.resetToken + "/?password=" + md5(this.password)).then(response => {
            this.$log("reset response ", response)
            if (response.status == 200) {
              EventBus.$emit("show-alert", {
                message: response.data.msg,
                color: "info",
                timeout: 7000
              })
            }
            this.resetToken = ""
            this.password = ""
            this.$router.push({ path: "/autentificare" })
          })
        } else {
          this.$log("recoverPassword ", this.recover)
          axios
            .get("request_pass_recovery/" + encodeURI(this.username))
            .then(response => {
              this.$log("recover response ", response)
              this.recover = false
              if (response.status == 200) {
                EventBus.$emit("show-alert", {
                  message: response.data.msg,
                  color: "info",
                  timeout: 7000
                })
              }
            })
            .catch(() => {
              this.recover = false
            })
        }
      }
      //this.recover = true
    },
    changeLang(e) {
      //this.$log("change lang ", e)
      this.$i18n.locale = e
      this.$store.commit("language", e)
    },
    login() {
      if (this.resetToken) {
        this.$refs.passwordConfirm.focus()
      } else {
        if (this.$refs.formChange.validate()) {
          //this.$log("login")
          this.$store
            .dispatch("login", { username: this.username, password: this.password })
            .then(() => this.$log("loginon"))
            .catch(error => {
              //this.$log("loginoff2", error.message)
              //this.$log("loginoff3", this.$t(error))
              EventBus.$emit("show-alert", {
                message: this.$t(error.message),
                color: "error",
                timeOut: 7000
              })
            })
        }
      }
    }
  }
}
</script>
